import { Button, Center } from "@mantine/core";
import { IconComponents, IconMessageDots } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useCallback, useRef } from "react";

export function UserVideoPreview({ videoData, isLoading }) {
  let { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleMouseEnter = useCallback(() => {
    if (videoRef.current) {
      const playPromise = videoRef.current.play();
      
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // Auto-play was prevented or interrupted
          console.log("Video play was prevented:", error);
        });
      }
    }
  }, []);
  
  const handleMouseLeave = useCallback(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      const playPromise = video.play();
      
      if (playPromise !== undefined) {
        playPromise.then(() => {
          video.pause();
          video.currentTime = 1.3;
        }).catch((error) => {
          console.log("Video play was prevented:", error);
        });
      }
    }
  }, []);
  let videoPreviewContent;

  if (videoData.mp4FileUrl) {
    let previewVideoClassname = " video-item__preview ";
    if (isLoading) {
      previewVideoClassname += " video-item__preview--pulsating ";
    }
    videoPreviewContent = (
      <>
        <video
          className={previewVideoClassname}
          ref={videoRef}
          src={videoData.mp4FileUrl + "#t=1.3"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          loop
          muted
          playsInline
          style={{}}
        ></video>
      </>
    );
  } else {
    let iconClassName = "text-color video-item__placeholder_icon ";
    if (isLoading) {
      iconClassName += " video-item__placeholder_icon--spinning ";
    }

    videoPreviewContent = (
      <Center style={{ height: "100%" }}>
        <IconComponents
          style={{ opacity: 0.5 }}
          className={iconClassName}
        ></IconComponents>
      </Center>
    );
  }

  return <div className={"video-item__preview_box"}>{videoPreviewContent}</div>;
}
